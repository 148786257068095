import styles from './HomeHero.module.scss'
import { ReactNode } from 'react'

interface HomeHeroProps {
  children?: ReactNode
  imageUrl?: string
  justifyContent?: 'top' | 'bottom' | 'center'
}

const HomeHero = (props: HomeHeroProps) => (
  <div
    className={styles.hero}
    style={{
      backgroundImage: `url(${props.imageUrl})`,
      justifyContent: props.justifyContent || 'top',
    }}
  >
    {props.children}
  </div>
)

export default HomeHero
