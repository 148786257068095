import Head from 'next/head'
import { GetServerSideProps, NextPage } from 'next/types'
import HomeHero from '../components/HomeHero'
import Header from '../components/Header'
import { getHeroImageUrl } from '../lib/home-hero'
import { CorsetTypeLinks } from '../components/home/CorsetTypeLinks'
import { PageFooter } from '../components/PageFooter'

interface HomeProps {
  heroImageUrl?: string
}

const Home: NextPage<HomeProps> = ({ heroImageUrl }) => (
  <>
    <Head>
      <title>BlueRabbit Corsets</title>
      <link rel="icon" type="image/png" href="/favicon.png" />
    </Head>

    <HomeHero imageUrl={heroImageUrl}>
      <Header />

      <CorsetTypeLinks />

      <PageFooter isHome />
    </HomeHero>

    <style jsx global>{`
      html,
      body {
        padding: 0;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
          Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      }

      * {
        box-sizing: border-box;
      }
    `}</style>
  </>
)

export const getServerSideProps: GetServerSideProps = async () => {
  const heroImageUrl = await getHeroImageUrl()

  return { props: { heroImageUrl } }
}

export default Home
