import styled from 'styled-components'
import { Container } from '../../styled'
import media from 'styled-media-query'
import { transparentize } from 'polished'

export const CorsetTypeLinksContainer = styled(Container)`
  justify-content: center;
  ${media.greaterThan('medium')`height: calc(100vh - 120px);`}
`

export const CorsetTypeLinkImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 60vh;
  opacity: 0.8;
`
export const CorsetTypeLink = styled.a`
  display: block;
  text-align: center;
  text-decoration: none;
  color: white;
  text-shadow: 0 2px 3px ${transparentize(0.5, 'black')};

  ${media.greaterThan('medium')`margin-left: 2rem;`}

  &:first-child {
    margin-left: 0;
  }

  img {
    transition: 0.2s all;
  }

  &:hover img,
  &:active img,
  &:focus img {
    transform: scale(1.1);
  }
`
