import React from 'react'
import {
  CorsetTypeLink,
  CorsetTypeLinkImage,
  CorsetTypeLinksContainer,
} from './styled'
import Link from 'next/link'

interface CorsetTypeListLinkProps {
  href: string
  type: 'overbusts' | 'underbusts'
}

export const CorsetTypeListLink = (props: CorsetTypeListLinkProps) => (
  <Link href={props.href}>
    <CorsetTypeLink href={props.href}>
      <CorsetTypeLinkImage src={`/portada-${props.type}.png`} />
      (click para entrar)
    </CorsetTypeLink>
  </Link>
)

export const CorsetTypeLinks = () => (
  <CorsetTypeLinksContainer row>
    <CorsetTypeListLink type={'underbusts'} href={'/corsets/underbust'} />
    <CorsetTypeListLink type={'overbusts'} href={'/corsets/overbust'} />
  </CorsetTypeLinksContainer>
)
